<script setup>
import { useNuxtApp } from '#imports';

const { $tp } = useNuxtApp();

import PopupCenter from '@/components/layout/popup/PopupCenter.vue';
import UiButton from '@/components/ui/button/UiButton.vue';

const props = defineProps({
  closeModal: {
    type: Function,
    default: undefined,
  },
  heading: {
    type: String,
    default: undefined,
  },
  title: {
    type: String,
    default: undefined,
  },
  description: {
    type: String,
    default: undefined,
  },
  buttonData: {
    // {action: () => {}, text: 'Закрыть'}
    type: Object,
    default: undefined,
  },
});

function actionButton() {
  props.buttonData.action?.(props.closeModal);
}
</script>
<template>
  <PopupCenter>
    <template #default>
      <div class="popup-info">
        <div v-if="props.heading" class="popup-info__heading">
          <h3
            class="popup-info__font popup-info__font--heading"
            v-html="$tp(props.heading)"
          />
        </div>

        <div v-if="props.title" class="popup-info__title">
          <h3
            class="popup-info__font popup-info__font--title"
            v-html="$tp(props.title)"
          />
        </div>

        <div v-if="props.description" class="popup-info__description">
          <p
            class="popup-info__font popup-info__font--description"
            v-html="$tp(props.description)"
          />
        </div>

        <div v-if="props.buttonData" class="popup-info__action">
          <UiButton
            class="popup-info__button"
            :text="props.buttonData.text"
            @click="actionButton"
          />
        </div>
      </div>
    </template>
  </PopupCenter>
</template>
<style scoped lang="scss">
.popup-info {
  $parent: &;

  width: em(672);
  padding: em(72) em(88) em(56);
  background-color: $color-white;

  @include media-breakpoint-down(sm) {
    width: 100%;
    max-width: em(672);
    padding: em(72) em(30) em(56);
  }

  &__font {
    &--heading {
      @include h4;

      color: $color-black-100;

      @include media-breakpoint-down(lg) {
        font-size: em(20);
      }

      @include media-breakpoint-down(sm) {
        @include h4-mob;
      }
    }

    &--title {
      @include subtitle-m;

      color: $color-black-100;

      @include media-breakpoint-down(sm) {
        @include subtitle-m-mob;
      }
    }

    &--description {
      @include text-s;

      color: $color-black-100;
    }
  }

  &__heading {
    margin-bottom: em(32);

    @include media-breakpoint-down(lg) {
      margin-bottom: em(23);
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: em(24);
    }
  }

  &__title {
    margin-bottom: em(16);

    @include media-breakpoint-down(sm) {
      margin-bottom: em(12);
    }
  }

  &__description {
    margin-top: em(16);

    @include media-breakpoint-down(sm) {
      margin-top: em(12);
    }
  }

  &__button {
    width: 100%;
    max-width: em(240);
    margin-top: em(48);
  }
}
</style>
